<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Create New Enquiry"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Create New Enquiry
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <!-- Button: Send -->
        <b-button
          variant="primary"
          class="mr-1"
          @click="sendEmail"
        >
          Create
        </b-button>
        <b-form-file
          ref="fileUploadinput"
          v-model="file"
          class="d-none"
        />
        <feather-icon
          icon="PaperclipIcon"
          size="17"
          class="ml-2 cursor-pointer"
          @click="openFilePicker"
        />
      </div>

      <!-- Footer: Right Content -->
      <div>

        <!-- Dropdown: More Actions -->
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="text-body"
            />
          </template>

          <b-dropdown-item>
            Add Label
          </b-dropdown-item>

          <b-dropdown-item>
            Plain Text Mode
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item>
            Print
          </b-dropdown-item>
          <b-dropdown-item>
            Check Spelling
          </b-dropdown-item>
        </b-dropdown>

        <!-- Discard Compose -->
        <feather-icon
          icon="TrashIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label"
        >Customer : </label>
        <v-select
          v-model="composeData.customerID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="customerName"
          :reduce="val => val.customerID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="customerList"
          input-id="email-to"
          :disabled="!allowCustomerSelection"
        >

          <template #option="{ avatar, customerName }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ customerName }}</span>
          </template>

          <template #selected-option="{ avatar, customerName }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ customerName }}</span>
          </template>
        </v-select>
      </div>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-site"
          class="form-label"
        >Site : </label>
        <v-select
          v-model="composeData.siteID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="siteName"
          :reduce="val => val.siteID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="siteList"
          input-id="email-site"
        >

          <template #option="{ avatar, siteName }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ siteName }}</span>
          </template>

          <template #selected-option="{ avatar, siteName }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ siteName }}</span>
          </template>
        </v-select>
      </div>
      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-building"
          class="form-label"
        >Building : </label>
        <v-select
          v-model="composeData.buildingID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="buildingName"
          :reduce="val => val.buildingID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="buildingList"
          input-id="email-building"
        >

          <template #option="{ avatar, buildingName }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ buildingName }}</span>
          </template>

          <template #selected-option="{ avatar, buildingName }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ buildingName }}</span>
          </template>
        </v-select>
      </div>
      <div class="compose-mail-form-field">
        <label
          for="email-line"
          class="form-label"
        >Line : </label>
        <v-select
          v-model="composeData.lineID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="lineName"
          :reduce="val => val.lineID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="lineList"
          input-id="email-line"
        >

          <template #option="{ avatar, lineName }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ lineName }}</span>
          </template>

          <template #selected-option="{ avatar, lineName }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ lineName }}</span>
          </template>
        </v-select>
      </div>
      <div class="compose-mail-form-field">
        <label
          for="email-asset"
          class="form-label"
        >Asset : </label>
        <v-select
          v-model="composeData.assetID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :reduce="val => val.assetID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="assetList"
          input-id="email-asset"
        >

          <template #option="{ avatar, name, assetID }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50">{{ assetID }} - {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name, assetID }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50">{{ assetID }} - {{ name }}</span>
          </template>
        </v-select>
      </div>
      <div class="compose-mail-form-field">
        <label
          for="email-device"
          class="form-label"
        >Device : </label>
        <v-select
          v-model="composeData.deviceID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="deviceID"
          :reduce="val => val.deviceID"
          class="flex-grow-1 email-to-selector"
          :close-on-select="true"
          :options="deviceList"
          input-id="email-device"
        >

          <template #option="{ avatar, name, deviceID }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50">{{ deviceID }} - {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name, deviceID }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50">{{ deviceID }} - {{ name }}</span>
          </template>
        </v-select>
      </div>
      <!-- Field: Cc (Hidden Initially)
      <div
        v-show="showCcField"
        class="compose-mail-form-field"
      >
        <label for="email-cc">CC: </label>
        <v-select
          v-model="composeData.cc"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          class="flex-grow-1 email-cc-selector"
          :close-on-select="false"
          :options="emailToOptions"
          input-id="email-cc"
        >

          <template #option="{ avatar, name }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ name }}</span>
          </template>
        </v-select>
      </div>
      -->
      <!-- Field: Bcc (Hidden Initially)
      <div
        v-show="showBccField"
        class="compose-mail-form-field"
      >
        <label for="email-bcc">Bcc </label>
        <v-select
          v-model="composeData.bcc"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          class="flex-grow-1 email-bcc-selector"
          :close-on-select="false"
          :options="emailToOptions"
          input-id="email-bcc"
        >

          <template #option="{ avatar, name }">
            <b-avatar
              size="sm"
              :src="avatar"
            />
            <span class="ml-50"> {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
            />
            <span class="ml-50"> {{ name }}</span>
          </template>
        </v-select>
      </div>
      -->

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label for="email-subject">Subject: </label>
        <b-form-input
          id="email-subject"
          v-model="composeData.subject"
        />
      </div>

      <!-- Field: Message - Quill Editor
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          v-model="composeData.message"
          :options="editorOption"
        />
        <div
          id="quill-toolbar"
          class="d-flex border-bottom-0"
        >
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>
      -->
      <div class="compose-mail-form-field">
        <b-list-group class="w-100">
          <b-list-group-item
            v-for="file in attachments"
            id="email-attachments"
            :key="file.name"
            class="d-flex justify-content-between align-items-center"
          >
            {{ file ? file.name : '' }}
            <feather-icon
              icon="TrashIcon"
              size="17"
              class="ml-75 cursor-pointer"
              @click="deleteAttachment(file)"
            />
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-form>

  </b-modal>
</template>

<script>
import store from '@/store'
import {
  BDropdown, BDropdownItem, BForm, BFormInput, BAvatar, BDropdownDivider, BButton, BFormFile, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import {
  getUserData,
} from '@/api/auth/utils'
import Ripple from 'vue-ripple-directive'
import { ref, watch, onUnmounted, reactive } from '@vue/composition-api'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import assetsLogStore from '../assets/assetsLogStore'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
    BFormFile,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-assets-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetsLogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const file = ref(null)
    const toast = useToast()
    const showCcField = ref(false)
    const showBccField = ref(false)
    const customerList = ref([])
    const lineList = ref([])
    const siteList = ref([])
    const buildingList = ref([])
    const cuurrentUserData = getUserData()
    const attachments = ref([])
    const allowCustomerSelection = cuurrentUserData.claims.includes('customer:all')
    const composeData = reactive({
      customerID: cuurrentUserData.customerID,
      siteID: '',
      buildingID: '',
      lineID: '',
      assetID: '',
      subject: '',
      deviceID: '',
      folder: 'new',
      isRead: false,
      replies: [],
    })
    const allAssets = ref([])
    const assetList = ref([])
    const deviceList = ref([])
    const isBusy = ref(false)

    const fileUploadinput = ref(null)

    const openFilePicker = () => {
      fileUploadinput.value.$refs.input.click()
    }

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Message',
    }

    /* eslint-enable global-require */
    const sendReply = (id, attachmentArray) => {
      const reply = {
        message: 'Attachments included in the Enquiry',
        isRead: false,
        attachments: attachmentArray,
      }
      const data = {
        emailID: id,
        reply,
      }

      store.dispatch('app-email/addReply', data)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          toast({
            component: ToastificationContent,
            props: {
              title: 'Enquiry Sent Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('reload-email', true)
          emit('update:shall-show-email-compose-modal', false)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          emit('reload-email', true)
        })
    }

    const uploadFiles = id => {
      const formData = new FormData()
      attachments.value.forEach(f => {
        formData.append('files', f)
      })

      store.dispatch('app-email/uploadAttachment', formData)
        .then(response => {
          sendReply(id, response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          emit('reload-email', true)
        })
    }

    const sendEmail = () => {
      console.log('sendEmail', composeData)
      store.dispatch('app-email/createEnquiry', composeData)
        .then(response => {
          console.log(response.data)
          const { id } = response.data
          uploadFiles(id)
        })
        .catch(e => {
          console.log('e', e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Email Sent Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const discardEmail = () => {
      emit('update:shall-show-email-compose-modal', false)
    }

    const unqueFilter = (array, key) => {
      const uniq = {}
      // eslint-disable-next-line no-return-assign
      return array.filter(obj => !uniq[obj[key]] && (uniq[obj[key]] = true))
    }

    const fetchAllAssets = () => {
      isBusy.value = true
      store
        .dispatch('app-assets-list/fetchAllAssets')
        .then(response => {
          const { data } = response
          allAssets.value = data
          customerList.value = unqueFilter(data, 'customerID') // [...new Set(assetList.map(a => a.customerName))]
          assetList.value = data.filter(asset => asset.customerID === composeData.customerID)
          deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
          lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
          buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
          siteList.value = unqueFilter(assetList.value, 'siteID') // [...new Set(assetList.map(a => a.siteName))]
        })
        .catch(e => {
          console.log('Error fetching assets list', e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    fetchAllAssets()
    watch(() => [composeData.customerID, composeData.siteID, composeData.buildingID, composeData.lineID, composeData.assetID], (val, prevVal) => {
      console.log('something changed')
      const [customerID, siteID, buildingID, lineID, assetID] = val
      const [prevCustomerID, prevSiteID, prevBuildingID, prevLineID, prevAssetID] = prevVal

      if (customerID !== prevCustomerID) {
        console.log('customerID changed')
        composeData.siteID = ''
        composeData.buildingID = ''
        composeData.lineID = ''
        composeData.assetID = null
        composeData.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
        buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
        siteList.value = unqueFilter(assetList.value, 'siteID') // [...new Set(assetList.map(a => a.siteName))]
      }
      if (siteID !== prevSiteID) {
        composeData.buildingID = ''
        composeData.lineID = ''
        composeData.assetID = null
        composeData.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
        buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
      }
      if (buildingID !== prevBuildingID) {
        composeData.lineID = ''
        composeData.assetID = null
        composeData.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID && asset.buildingID === buildingID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
      }
      if (lineID !== prevLineID) {
        composeData.assetID = null
        composeData.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID && asset.buildingID === buildingID && asset.lineID === lineID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
      }
      if (assetID !== prevAssetID) {
        if (assetID) {
          const asset = assetList.value.find(a => a.assetID === assetID)
          composeData.deviceID = asset.deviceID
        }
      }
    })

    watch(file, (val, prevVal) => {
      if (val && val.name !== prevVal?.name) {
        const { size } = val
        const fileSize = size / 1024 / 1024
        if (fileSize > 50) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'File size should be less than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          file.value = null
        } else {
          attachments.value.push(val)
        }
      }
    })

    const deleteAttachment = attachment => {
      const index = attachments.value.findIndex(f => f.name === attachment.name)
      if (index > -1) {
        attachments.value.splice(index, 1)
      }
    }

    return {
      composeData,
      editorOption,
      showCcField,
      showBccField,
      allowCustomerSelection,
      customerList,
      lineList,
      siteList,
      buildingList,
      assetList,
      deviceList,
      file,
      openFilePicker,
      sendEmail,
      discardEmail,
      fileUploadinput,
      attachments,
      deleteAttachment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
