<template>
  <div class="email-app-details" v-if="ready">
    <div class="email-detail-header">
      <b-row>
        <b-col cols="12">
          <!-- Header: Left -->
          <div class="email-header-left d-flex align-items-center">
            <span class="go-back mr-1">
              <feather-icon
                :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                size="20"
                class="align-bottom"
                @click="$emit('close-email-view')"
              />
            </span>
            <!-- Field: To -->
            <div class="compose-mail-form-field">
              <label
                for="email-to"
                class="form-label"
              >Customer : </label>
              <v-select
                v-model="emailOpened.customerID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="customerName"
                :reduce="val => val.customerID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="customerList"
                input-id="email-to"
                :disabled="!allowCustomerSelection"
              />
            </div>

            <!-- Field: To -->
            <div class="compose-mail-form-field">
              <label
                for="email-site"
                class="form-label"
              >Site : </label>
              <v-select
                v-model="emailOpened.siteID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="siteName"
                :reduce="val => val.siteID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="siteList"
                input-id="email-site"
              />
            </div>
            <!-- Field: To -->
            <div class="compose-mail-form-field">
              <label
                for="email-building"
                class="form-label"
              >Building : </label>
              <v-select
                v-model="emailOpened.buildingID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="buildingName"
                :reduce="val => val.buildingID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="buildingList"
                input-id="email-building"
              />
            </div>
            <div class="compose-mail-form-field">
              <label
                for="email-line"
                class="form-label"
              >Line : </label>
              <v-select
                v-model="emailOpened.lineID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="lineName"
                :reduce="val => val.lineID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="lineList"
                input-id="email-line"
              />
            </div>
            <div class="compose-mail-form-field">
              <label
                for="email-asset"
                class="form-label"
              >Asset : </label>
              <v-select
                v-model="emailOpened.assetID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :reduce="val => val.assetID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="assetList"
                input-id="email-asset"
              />
            </div>
            <div class="compose-mail-form-field">
              <label
                for="email-device"
                class="form-label"
              >Device : </label>
              <v-select
                v-model="emailOpened.deviceID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="deviceID"
                :reduce="val => val.deviceID"
                class="flex-grow-1 email-to-selector"
                :close-on-select="true"
                :options="deviceList"
                input-id="email-device"
              />
            </div>
            <div class="compose-mail-form-field">
              <b-button
                variant="primary"
                class="mr-1 mt-2 ml-2"
                @click="saveEmail"
              >
                Save
              </b-button>
            </div>
          </div>

        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col cols="12">
          <div class="email-detail-header" style="height: 5rem !important;">
            <div class="email-header-left d-flex align-items-center">
              <h4 class="email-subject">
                {{ emailOpened.subject }}
              </h4>
            </div>

            <!-- Header: Right -->
            <div class="email-header-right ml-2 pl-1">
              <!-- Move email to folder -->
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
                class="ml-75"
              >
                <template #button-content>
                  <feather-icon
                    icon="FolderIcon"
                    size="17"
                  />
                </template>

                <b-dropdown-item @click="$emit('move-email-to-folder', 'maintenance')">
                  <feather-icon icon="FolderIcon" />
                  <span class="align-middle ml-50">Maintenance</span>
                </b-dropdown-item>

                <b-dropdown-item @click="$emit('move-email-to-folder', 'breakdown')">
                  <feather-icon icon="FolderIcon" />
                  <span class="align-middle ml-50">Breakdown</span>
                </b-dropdown-item>

                <b-dropdown-item @click="$emit('move-email-to-folder', 'lineconfig')">
                  <feather-icon icon="FolderIcon" />
                  <span class="align-middle ml-50">Line Configuration</span>
                </b-dropdown-item>
              </b-dropdown>

              <!-- Mark Un-Read -->
              <feather-icon
                icon="MailIcon"
                class="ml-75 cursor-pointer"
                size="17"
                @click="$emit('mark-email-unread')"
              />

              <!-- Move to Trash -->
              <feather-icon
                v-show="$route.params.folder !== 'deleted'"
                icon="TrashIcon"
                class="ml-75"
                size="17"
                @click="$emit('move-email-to-folder', 'deleted')"
              />

              <!-- Show Previous Mail -->
              <feather-icon
                :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                size="17"
                class="ml-75 cursor-pointer"
                :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasPreviousEmail}"
                @click="$emit('change-opened-email', 'previous')"
              />

              <!-- Show Next Mail -->
              <feather-icon
                :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                size="17"
                class="ml-75 cursor-pointer"
                :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasNextEmail}"
                @click="$emit('change-opened-email', 'next')"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      ref="emailScrollArea"
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Action: Show Earlier Message -->
      <b-row
        v-if="!showWholeThread && emailOpened.replies && emailOpened.replies.length"
        class="mb-1"
      >
        <b-col cols="12">
          <b-link
            class="font-weight-bold"
            @click="showWholeThread = true"
          >
            View {{ emailOpened.replies.length }} Earlier Message{{ emailOpened.replies.length > 1 ? 's' : '' }}
          </b-link>
        </b-col>
      </b-row>

      <!-- Earlier Email Messages -->
      <template v-if="showWholeThread">
        <b-row
          v-for="threadMail in emailOpened.replies"
          :key="threadMail.id"
        >
          <b-col cols="12">
            <email-message-card :message="threadMail" />
          </b-col>
        </b-row>
      </template>
      <b-row class="">

        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                <b-link
                  @click="showReplyCard = true"
                >
                  Reply
                </b-link>
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- Message Input -->
      <b-row
        v-if="showReplyCard"
        class="fixed-bottom"
      >
        <b-col cols="12">
          <b-card class="mb-0">
            <b-form
              class="chat-app-form"
              @submit.prevent="uploadFiles"
            >
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input
                  v-model="reply.message"
                  placeholder="Enter your message"
                />
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Send
                </b-button>
                <b-form-file
                  ref="fileUploadinput"
                  v-model="file"
                  class="d-none"
                />
                <feather-icon
                  icon="PaperclipIcon"
                  size="17"
                  class="ml-2 cursor-pointer mt-1"
                  @click="openFilePicker"
                />
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  :dropup="true"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-for="file in reply.attachments"
                    :key="file.name"
                    class="d-flex justify-content-between align-items-center"
                  >
                    {{ file ? file.name : '' }}
                    <feather-icon
                      icon="TrashIcon"
                      size="17"
                      class="ml-75 cursor-pointer"
                      @click="deleteAttachment(file)"
                    />
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="reply.attachments.length === 0"
                    class="d-flex justify-content-between align-items-center"
                  >
                    No Attachments
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BDropdown, BDropdownItem, BRow, BCol, BCard, BLink, BForm, BInputGroup, BFormInput, BButton, BFormFile,
} from 'bootstrap-vue'
import {
  getUserData,
} from '@/api/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  ref, watch, reactive, onUnmounted,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import EmailMessageCard from './EmailMessageCard.vue'
import assetsLogStore from '../assets/assetsLogStore'

export default {
  components: {

    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BLink,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormFile,
    vSelect,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    EmailMessageCard,
  },
  props: {
    emailData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-assets-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetsLogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const cuurrentUserData = getUserData()
    const allowCustomerSelection = cuurrentUserData.claims.includes('customer:all')
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const toast = useToast()
    const { emailData } = props
    const emailOpened = reactive(emailData)
    const emailScrollArea = ref(null)
    const showReplyCard = ref(false)
    const ready = ref(false)
    const file = ref(null)
    const fileUploadinput = ref(null)
    const customerList = ref([])
    const lineList = ref([])
    const siteList = ref([])
    const buildingList = ref([])
    const allAssets = ref([])
    const assetList = ref([])
    const deviceList = ref([])
    const isBusy = ref(false)
    const openFilePicker = () => {
      fileUploadinput.value.$refs.input.click()
    }
    const reply = ref({
      message: '',
      isRead: false,
      attachments: [],
    })

    const showWholeThread = ref(true)

    const sendMessage = () => {
      const data = {
        emailID: emailOpened.id,
        reply: reply.value,
      }
      store.dispatch('app-email/addReply', data)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          emit('reload-email', true)
          Object.assign(emailOpened, response.data)
          showReplyCard.value = false
          reply.value.message = ''
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          emit('reload-email', true)
        })
    }

    const uploadFiles = () => {
      const formData = new FormData()
      reply.value.attachments.forEach(f => {
        formData.append('files', f)
      })

      store.dispatch('app-email/uploadAttachment', formData)
        .then(response => {
          reply.value.attachments = response.data
          sendMessage()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          emit('reload-email', true)
        })
    }

    const unqueFilter = (array, key) => {
      const uniq = {}
      // eslint-disable-next-line no-return-assign
      return array.filter(obj => !uniq[obj[key]] && (uniq[obj[key]] = true))
    }

    const fetchAllAssets = () => {
      isBusy.value = true
      store
        .dispatch('app-assets-list/fetchAllAssets')
        .then(response => {
          console.log({ ...emailOpened })
          const { data } = response
          allAssets.value = data
          customerList.value = unqueFilter(data, 'customerID') // [...new Set(assetList.map(a => a.customerName))]
          assetList.value = data.filter(asset => asset.customerID === emailOpened.customerID)
          deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
          lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
          buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
          siteList.value = unqueFilter(assetList.value, 'siteID') // [...new Set(assetList.map(a => a.siteName))]
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
          ready.value = true
          setTimeout(() => {
            emailScrollArea.value.$el.scrollTop = emailScrollArea.value.$el.scrollHeight + 100000
          }, 1000)
        })
    }

    fetchAllAssets()
    watch(() => [emailOpened.replies], () => {
      setTimeout(() => {
        emailScrollArea.value.$el.scrollTop = emailScrollArea.value.$el.scrollHeight + 100000
      }, 1000)
    })
    watch(() => [emailOpened.customerID, emailOpened.siteID, emailOpened.buildingID, emailOpened.lineID, emailOpened.assetID], (val, prevVal) => {
      console.log('something changed')
      const [customerID, siteID, buildingID, lineID, assetID] = val
      const [prevCustomerID, prevSiteID, prevBuildingID, prevLineID, prevAssetID] = prevVal

      if (customerID !== prevCustomerID) {
        console.log('customerID changed')
        emailOpened.siteID = ''
        emailOpened.buildingID = ''
        emailOpened.lineID = ''
        emailOpened.assetID = null
        emailOpened.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
        buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
        siteList.value = unqueFilter(assetList.value, 'siteID') // [...new Set(assetList.map(a => a.siteName))]
      }
      if (siteID !== prevSiteID) {
        emailOpened.buildingID = ''
        emailOpened.lineID = ''
        emailOpened.assetID = null
        emailOpened.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
        buildingList.value = unqueFilter(assetList.value, 'buildingID') // [...new Set(assetList.map(a => a.buildingName))]
      }
      if (buildingID !== prevBuildingID) {
        emailOpened.lineID = ''
        emailOpened.assetID = null
        emailOpened.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID && asset.buildingID === buildingID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
        lineList.value = unqueFilter(assetList.value, 'lineID') // [...new Set(assetList.map(a => a.lineName))]
      }
      if (lineID !== prevLineID) {
        emailOpened.assetID = null
        emailOpened.deviceID = ''
        assetList.value = allAssets.value.filter(asset => asset.customerID === customerID && asset.siteID === siteID && asset.buildingID === buildingID && asset.lineID === lineID)
        deviceList.value = unqueFilter(assetList.value, 'deviceID') // [...new Set(assetList.map(a => a.deviceID))]
      }
      if (assetID !== prevAssetID) {
        if (assetID) {
          const asset = assetList.value.find(a => a.assetID === assetID)
          emailOpened.deviceID = asset.deviceID
        }
      }
    })

    const saveEmail = () => {
      isBusy.value = true
      store.dispatch('app-email/updateEnquiry', emailOpened)
        .then(response => {
          const { data } = response
          console.log({ data })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Email saved successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error saving email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    watch(file, (val, prevVal) => {
      if (val && val.name !== prevVal?.name) {
        const { size } = val
        const fileSize = size / 1024 / 1024
        if (fileSize > 50) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'File size should be less than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          file.value = null
        } else {
          reply.value.attachments.push(val)
        }
      }
    })

    const deleteAttachment = attachment => {
      const index = reply.value.attachments.findIndex(f => f.name === attachment.name)
      if (index > -1) {
        reply.value.attachments.splice(index, 1)
      }
    }

    return {

      // UI
      ready,
      reply,
      showReplyCard,
      perfectScrollbarSettings,
      showWholeThread,
      emailOpened,
      emailScrollArea,
      uploadFiles,
      isBusy,
      customerList,
      siteList,
      buildingList,
      lineList,
      assetList,
      deviceList,
      allowCustomerSelection,
      saveEmail,
      file,
      fileUploadinput,
      openFilePicker,
      deleteAttachment,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.vs__selected {
  font-size: 7px !important;
}

.form-label{
  font-size: 10px;
}
</style>
